<template>
  <v-container fluid class="hint-size">
    <v-row>
      <v-col
        cols="12"
        :align="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
      >
        <div
          class="text-sm-h5"
          :class="primary ? 'primary--text' : 'secondary--text'"
        >
          Información personal
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" align="start">
        <div>Primer nombre</div>
        <v-text-field
          v-model.trim="patient.firstName"
          outlined
          placeholder="Escriba"
          :rules="[rules.required]"
          required
          maxlength="100"
          counter
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" align="start">
        <div>Segundo nombre</div>
        <v-text-field
          v-model.trim="patient.middleName"
          outlined
          persistent-hint
          hint="Opcional"
          placeholder="Escriba"
          maxlength="100"
          counter
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" align="start">
        <div>Primer apellido</div>
        <v-text-field
          v-model.trim="patient.lastName"
          outlined
          placeholder="Escriba"
          :rules="[rules.required]"
          required
          maxlength="100"
          counter
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" align="start">
        <div>Segundo apellido</div>
        <v-text-field
          v-model.trim="patient.secondLastName"
          outlined
          persistent-hint
          hint="Opcional"
          placeholder="Escriba"
          maxlength="100"
          counter
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" align="start">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <div>Fecha de nacimiento</div>
            <v-text-field
              v-model="computedDateFormatted"
              hint="DD/MM/AAAA"
              persistent-hint
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              class="my-0"
              :rules="[rules.required]"
              placeholder="Seleccione"
              maxlength="100"
              required
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="date"
            no-title
            :max="maxDate()"
            @input="menu = false"
            color="primary"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6" align="start">
        <div>Lugar de nacimiento</div>
        <v-text-field
          v-model.trim="patient.placeOfBirth"
          outlined
          persistent-hint
          hint="Indica  ciudad y país."
          placeholder="Escriba"
          :rules="[rules.required]"
          maxlength="300"
          counter
          required
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" align="start">
        <div>Correo electrónico</div>
        <v-text-field
          v-model.trim="patient.email"
          outlined
          placeholder="Escriba"
          :rules="[rules.required, emailRules[0], emailRules[1]]"
          required
          maxlength="1000"
          counter
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" align="start">
        <div>Tlf. celular</div>
        <v-text-field
          v-model="patient.phone"
          outlined
          placeholder="(424) 456-7890"
          :rules="[rules.required]"
          maxlength="50"
          counter
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" align="start">
        <div>Género</div>
        <v-select
          :items="genderOptions"
          return-object
          v-model="patient.gender"
          outlined
          attach
          :rules="[rules.required]"
          placeholder="Seleccione"
          required
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" align="start">
        <div>Profesión u oficio</div>
        <v-text-field
          v-model="patient.profession"
          outlined
          :rules="[rules.required]"
          placeholder="Escriba"
          maxlength="300"
          counter
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" align="start">
        <div>Estado civil</div>
        <v-select
          :items="civilStatusOptions"
          return-object
          v-model="patient.civilStatus"
          outlined
          :rules="[rules.required]"
          placeholder="Seleccione"
          required
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="start">
        <div>
          Tiempo de relación de pareja / separación / fallecimiento. Comenta su
          nombre completo, profesión y si tuvieron hijos.
        </div>
        <v-textarea
          v-model.trim="patient.relationship"
          outlined
          persistent-hint
          hint="Toma en cuenta los abortos o pérdidas. Esta pregunta es opcional."
          placeholder="Escriba"
          maxlength="1000"
          counter
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" align="start">
        <div>¿Tiene hijos?</div>
        <v-select
          :items="childrenOptions"
          return-object
          v-model="patient.children"
          outlined
          label=""
          :rules="[rules.required]"
          placeholder="Seleccione"
          required
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="start">
        <div>
          ¿Has tenido pérdidas o abortos? Comenta brevemente lo que sucedió.
          Aplica si eres hombre.
        </div>
        <v-textarea
          v-model.trim="patient.childrenLosses"
          outlined
          persistent-hint
          hint="Si sabes el lugar que ocupan entre tus hijos señalarlo. Esta pregunta es opcional."
          placeholder="Escriba"
          maxlength="1000"
          counter
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="start">
        <div>
          De la unión de mamá y papá biológicos. ¿Qué número de hij@ eres?
        </div>
        <v-select
          :items="childPositionOptions"
          return-object
          v-model="patient.childPosition"
          outlined
          :rules="[rules.required]"
          placeholder="Seleccione"
          required
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="start">
        <div>
          Si en la respuesta anterior seleccionaste OTRO. Indica cuál número de
          hijo eres
        </div>
        <v-text-field
          v-model="patient.childPositionAlt"
          outlined
          placeholder="Escriba"
          maxlength="100"
          :disabled="patient.childPosition !== 'Otro'"
          counter
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="update && adminView">
      <v-col cols="12" align="start">
        <div>
          ¿Sabes si mamá tuvo una pérdida o aborto, el lugar que ocupa y cómo
          fue?
        </div>
        <v-textarea
          v-model.trim="patient.motherLosses"
          outlined
          placeholder="Escriba"
          persistent-hint
          hint="Esta pregunta es opcional."
          maxlength="50000"
          counter
        ></v-textarea>
      </v-col>
      <v-col cols="12" align="start">
        <div>
          ¿Cómo fue / es la relación de pareja entre papá y mamá? Detalla.
        </div>
        <v-textarea
          v-model.trim="patient.parentsRelationship"
          outlined
          persistent-hint
          hint="Comenta si hubo hijos fuera del matrimonio, lo que sepas de ellos incluyendo nombres. Esta pregunta es opcional."
          placeholder="Escriba"
          maxlength="50000"
          counter
        ></v-textarea>
      </v-col>
    </v-row>
    <template v-if="!update">
      <hr />
      <v-row class="mt-4">
        <v-col
          cols="12"
          :align="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
        >
          <div
            class="text-sm-h5"
            :class="primary ? 'primary--text' : 'secondary--text'"
          >
            Información familiar
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          :align="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
        >
          <h4
            class="text-sm-h6"
            :class="primary ? 'primary--text' : 'secondary--text'"
          >
            Padres
          </h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" align="start">
          <div>Nombre completo de papá biológico</div>
          <v-text-field
            v-model.trim="father.name"
            outlined
            placeholder="Escriba"
            persistent-hint
            maxlength="200"
            counter
            hint="Si lo conoces o sabes de él.  Esta pregunta es opcional."
          ></v-text-field>
          <div>Descripción de papá biológico</div>
          <v-textarea
            v-model.trim="father.content"
            outlined
            persistent-hint
            hint="Comenta su nacionalidad, profesión, si está vivo o la causa de su fallecimiento; lo que sepas de él y su relación contigo. Esta pregunta es opcional."
            placeholder="Escriba"
            maxlength="1000"
            counter
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6" align="start">
          <div>Nombre completo de mamá biológica</div>
          <v-text-field
            v-model.trim="mother.name"
            outlined
            placeholder="Escriba"
            persistent-hint
            maxlength="200"
            counter
            hint="Si la conoces o sabes de ella. Esta pregunta es opcional."
          ></v-text-field>
          <div>Descripción de mamá biológica</div>
          <v-textarea
            v-model.trim="mother.content"
            outlined
            persistent-hint
            hint="Comenta su nacionalidad, profesión, si está viva o la causa de su fallecimiento lo que sepas de ella y su relación contigo. Esta pregunta es opcional."
            placeholder="Escriba"
            maxlength="1000"
            counter
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" align="start">
          <div>
            ¿Sabes si mamá tuvo una pérdida o aborto, el lugar que ocupa y cómo
            fue?
          </div>
          <v-textarea
            v-model.trim="patient.motherLosses"
            outlined
            placeholder="Escriba"
            persistent-hint
            hint="Esta pregunta es opcional."
            maxlength="1000"
            counter
          ></v-textarea>
        </v-col>
        <v-col cols="12" align="start">
          <div>
            ¿Cómo fue / es la relación de pareja entre papá y mamá? Detalla.
          </div>
          <v-textarea
            v-model.trim="patient.parentsRelationship"
            outlined
            persistent-hint
            hint="Comenta si hubo hijos fuera del matrimonio, lo que sepas de ellos incluyendo nombres. Esta pregunta es opcional."
            placeholder="Escriba"
            maxlength="1000"
            counter
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          :align="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
        >
          <h4
            class="text-sm-h6"
            :class="primary ? 'primary--text' : 'secondary--text'"
          >
            Abuelos paternos
          </h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" align="start">
          <div>Nombre del abuelo paterno</div>
          <v-text-field
            v-model.trim="paternalGrandfather.name"
            outlined
            placeholder="Escriba"
            persistent-hint
            maxlength="200"
            counter
            hint="Si lo conoces o sabes de él.  Esta pregunta es opcional."
          ></v-text-field>
          <div>Descripción del abuelo paterno</div>
          <v-textarea
            v-model.trim="paternalGrandfather.content"
            outlined
            persistent-hint
            hint="Comenta su nacionalidad, profesión, si está vivo o la causa de su fallecimiento; lo que sepas de él y su relación contigo. Esta pregunta es opcional."
            placeholder="Escriba"
            maxlength="1000"
            counter
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6" align="start">
          <div>Nombre de la abuela paterna</div>
          <v-text-field
            v-model.trim="paternalGrandmother.name"
            outlined
            placeholder="Escriba"
            persistent-hint
            maxlength="200"
            counter
            hint="Si la conoces o sabes de ella. Esta pregunta es opcional."
          ></v-text-field>
          <div>Descripción de la abuela paterna</div>
          <v-textarea
            v-model.trim="paternalGrandmother.content"
            outlined
            persistent-hint
            hint="Comenta su nacionalidad, profesión, si está viva o la causa de su fallecimiento lo que sepas de ella y su relación contigo. Esta pregunta es opcional."
            placeholder="Escriba"
            maxlength="1000"
            counter
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          :align="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
        >
          <h4
            class="text-sm-h6"
            :class="primary ? 'primary--text' : 'secondary--text'"
          >
            Abuelos maternos
          </h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" align="start">
          <div>Nombre del abuelo materno</div>
          <v-text-field
            v-model.trim="maternalGrandfather.name"
            outlined
            placeholder="Escriba"
            persistent-hint
            maxlength="200"
            counter
            hint="Si lo conoces o sabes de él.  Esta pregunta es opcional."
          ></v-text-field>
          <div>Descripción del abuelo materno</div>
          <v-textarea
            v-model.trim="maternalGrandfather.content"
            outlined
            persistent-hint
            hint="Comenta su nacionalidad, profesión, si está vivo o la causa de su fallecimiento; lo que sepas de él y su relación contigo. Esta pregunta es opcional."
            placeholder="Escriba"
            maxlength="1000"
            counter
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6" align="start">
          <div>Nombre de la abuela materna</div>
          <v-text-field
            v-model.trim="maternalGrandmother.name"
            outlined
            placeholder="Escriba"
            persistent-hint
            maxlength="200"
            counter
            hint="Si la conoces o sabes de ella. Esta pregunta es opcional."
          ></v-text-field>
          <div>Descripción de la abuela materna</div>
          <v-textarea
            v-model.trim="maternalGrandmother.content"
            outlined
            persistent-hint
            hint="Comenta su nacionalidad, profesión, si está viva o la causa de su fallecimiento lo que sepas de ella y su relación contigo. Esta pregunta es opcional."
            placeholder="Escriba"
            maxlength="1000"
            counter
          ></v-textarea>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { KINSHIPS } from "@/config/constants";
export default {
  name: "resgitration-basic-data",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    update: {
      type: Boolean,
      default: false
    },
    patientToUpdate: {
      type: Object,
      default: null
    },
    primary: {
      type: Boolean,
      default: false
    },
    adminView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false,
      date: "",
      patient: {
        firstName: "",
        middleName: "",
        lastName: "",
        secondLastName: "",
        email: "",
        placeOfBirth: "",
        birthdate: "",
        gender: "",
        phone: "",
        civilStatus: "",
        profession: "",
        relationship: "",
        childrenLosses: "",
        motherLosses: "",
        parentsRelationship: "",
        children: "",
        childPosition: "",
        childPositionAlt: ""
      },
      mother: {
        name: "",
        content: ""
      },
      father: {
        name: "",
        content: ""
      },
      maternalGrandmother: {
        name: "",
        content: ""
      },
      maternalGrandfather: {
        name: "",
        content: ""
      },
      paternalGrandmother: {
        name: "",
        content: ""
      },
      paternalGrandfather: {
        name: "",
        content: ""
      },
      genderOptions: ["Hombre", "Mujer", "Otro"],
      civilStatusOptions: [
        "Solter@",
        "Casad@",
        "Unión libre / Concubinato",
        "Divorciad@",
        "Viud@",
        "Otro"
      ],
      childrenOptions: ["Sí", "No", "En gestación"],
      childPositionOptions: [
        "Hij@ únic@",
        "Primer@",
        "Segund@",
        "Tercer@",
        "Cuart@",
        "Quint@",
        "Otro"
      ],
      rules: {
        required: value => !!value || "Campo Obligatorio"
      },
      emailRules: [
        value => /.+@.+\..+/.test(value) || "Formato de correo no válido",
        value =>
          /^\S+$/.test(value) ||
          "El correo no puede contener espacios en blanco"
      ]
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    retrieveData() {
      return this.formatData();
    },
    maxDate() {
      let date = new Date();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    formatData() {
      let patientData = {
        ...this.patient,
        relatives: [
          {
            name:
              this.patient.firstName +
              " " +
              this.patient.middleName +
              " " +
              this.patient.lastName +
              " " +
              this.patient.secondLastName,
            content: "",
            kinship: { id: KINSHIPS.ME.id }
          },
          {
            ...this.mother,
            kinship: { id: KINSHIPS.MOTHER.id }
          },
          {
            ...this.father,
            kinship: { id: KINSHIPS.FATHER.id }
          },
          {
            ...this.maternalGrandmother,
            kinship: { id: KINSHIPS.MATERNAL_GRANDMOTHER.id }
          },
          {
            ...this.maternalGrandfather,
            kinship: { id: KINSHIPS.MATERNAL_GRANDFATHER.id }
          },
          {
            ...this.paternalGrandmother,
            kinship: { id: KINSHIPS.PATERNAL_GRANDMOTHER.id }
          },
          {
            ...this.paternalGrandfather,
            kinship: { id: KINSHIPS.PATERNAL_GRANDFATHER.id }
          },
          {
            name: "",
            content: "",
            kinship: { id: KINSHIPS.MOTHER_MATERNAL_GRANDMOTHER.id }
          },
          {
            name: "",
            content: "",
            kinship: { id: KINSHIPS.FATHER_MATERNAL_GRANDMOTHER.id }
          },
          {
            name: "",
            content: "",
            kinship: { id: KINSHIPS.MOTHER_MATERNAL_GRANDFATHER.id }
          },
          {
            name: "",
            content: "",
            kinship: { id: KINSHIPS.FATHER_MATERNAL_GRANDFATHER.id }
          },
          {
            name: "",
            content: "",
            kinship: { id: KINSHIPS.MOTHER_PATERNAL_GRANDMOTHER.id }
          },
          {
            name: "",
            content: "",
            kinship: { id: KINSHIPS.FATHER_PATERNAL_GRANDMOTHER.id }
          },
          {
            name: "",
            content: "",
            kinship: { id: KINSHIPS.MOTHER_PATERNAL_GRANDFATHER.id }
          },
          {
            name: "",
            content: "",
            kinship: { id: KINSHIPS.FATHER_PATERNAL_GRANDFATHER.id }
          }
        ]
      };
      return patientData;
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    }
  },
  watch: {
    computedDateFormatted(newValue) {
      this.patient.birthdate = newValue;
    },
    menu() {
      setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    "patient.childPosition"(newValue) {
      if (newValue !== "Otro") {
        this.patient.childPositionAlt = "";
      }
    }
  },
  mounted() {
    if (this.patientToUpdate) {
      this.patient = { ...this.patientToUpdate };
      const [day, month, year] = this.patientToUpdate.birthdate.split("/");
      this.date = `${year}-${month}-${day}`;
    }
  }
};
</script>
<style scoped>
.hint-size ::v-deep .v-messages__message {
  line-height: 1.375rem;
  font-size: 0.875rem;
}
</style>
