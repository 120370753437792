<template>
  <v-container
    fill-height
    class="error-position d-flex justify-center align-start pt-12"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        class="d-flex justify-center align-center error-image-shadow"
      >
        <v-icon
          class="error-text-main"
          :size="$vuetify.breakpoint.smAndDown ? '100px' : '200px'"
          >{{ icon }}</v-icon
        >
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-center align-center error-text-main error-text"
      >
        <h1
          :class="
            $vuetify.breakpoint.smAndDown ? 'headline font-weight-medium' : ''
          "
        >
          {{ title }}
        </h1>
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-center align-center error-text-color error-text"
      >
        <h2
          :class="
            $vuetify.breakpoint.smAndDown ? 'title font-weight-medium' : ''
          "
        >
          {{ subtitle }}
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "empty-section",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped>
.error-text-color {
  color: #b3b9bc !important;
}
.error-text-main {
  color: #919699 !important;
}
.error-image-shadow {
  -webkit-filter: drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.3));
}
.error-text {
  text-align: center !important;
}
.error-position {
  min-height: 75vh !important;
}
</style>
