<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :align="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
      >
        <div
          class="text-sm-h5 text-body-1"
          :class="primary ? 'primary--text' : 'secondary--text'"
        >
          {{ section.name }}
        </div>
        <h5
          :class="primary ? 'primary--text' : 'secondary--text'"
          class="text-sm-body-2"
        >
          {{ section.description }}
        </h5>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(schema, index) in schemas"
        :key="`${index}-${schema.label}`"
        cols="11"
        align="start"
      >
        <form-input :schema="schema" :ref="`input-${schema.fields[0].id}`" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormInput from "./FormInput";
import { QUESTION_TYPES } from "@/config/constants";
export default {
  name: "patient-form-section",
  components: {
    "form-input": FormInput
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    update: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      schemas: []
    };
  },
  methods: {
    returnInputModelName(questionType) {
      switch (questionType) {
        case QUESTION_TYPES.SINGLE_CHOICE.id:
          return QUESTION_TYPES.SINGLE_CHOICE.name;
        case QUESTION_TYPES.MULTIPLE_CHOICE.id:
          return QUESTION_TYPES.MULTIPLE_CHOICE.name;
        case QUESTION_TYPES.TEXT.id:
          return QUESTION_TYPES.TEXT.name;
        case QUESTION_TYPES.TEXTAREA.id:
          return QUESTION_TYPES.TEXTAREA.name;
        case QUESTION_TYPES.DATE.id:
          return QUESTION_TYPES.DATE.name;
        case QUESTION_TYPES.SWITCH.id:
          return QUESTION_TYPES.SWITCH.name;
      }
    },
    formatQuestion(question) {
      let schema = {
        fields: [
          {
            type: this.returnInputModelName(question.questionType.id),
            id: question.id,
            label: `${question.position}.- ${question.name}`,
            model: "value",
            placeholder: question.label,
            hint: question.hint,
            required: question.required,
            position: question.position,
            disabled: false,
            readonly: false,
            adminView: false,
            startValue: null
          }
        ]
      };
      if (this.update) {
        schema.fields[0].startValue = this.initializeValues(question);
      }
      if (
        question.questionType.id === QUESTION_TYPES.SINGLE_CHOICE.id ||
        question.questionType.id === QUESTION_TYPES.MULTIPLE_CHOICE.id
      ) {
        schema.fields[0] = { ...schema.fields[0], options: question.options };
      } else {
        if (question.questionType.id === QUESTION_TYPES.SWITCH.id) {
          schema.fields[0] = {
            ...schema.fields[0],
            multi: true,
            featured: false,
            default: false,
            textOn: "Verdadero",
            textOff: "Falso"
          };
        }
      }
      return schema;
    },
    initializeValues(question) {
      let initialValue = null;
      if (question.answers?.length) {
        if (question.answers[0].content) {
          if (question.questionType.id === QUESTION_TYPES.SWITCH.id) {
            initialValue =
              question.answers[0].content === "true" ? true : false;
          } else {
            initialValue = question.answers[0].content;
          }
        } else {
          if (question.answers[0].optionAnswers.length) {
            initialValue = question.answers[0].optionAnswers;
          }
        }
      }
      return initialValue;
    },
    createSchemas(questions) {
      questions.forEach(question => {
        let questionSchema = this.formatQuestion(question);
        this.schemas.push(questionSchema);
      });
    },
    retrieveData() {
      let data = [];
      this.schemas.forEach(schema => {
        let value = this.$refs[
          `input-${schema.fields[0].id}`
        ][0].retrieveData();
        if (
          (value.content !== null && value.content !== "") ||
          value.optionAnswers?.length
        ) {
          if (this.update) {
            let question = this.section.questions.find(
              qstn => qstn.id === schema.fields[0].id
            );
            if (question.answers?.length) {
              data.push({ id: question.answers[0].id, ...value });
            } else {
              data.push(value);
            }
          } else {
            data.push(value);
          }
        }
      });
      return data;
    }
  },
  mounted() {
    this.createSchemas(this.section.questions);
  }
};
</script>

<style scoped></style>
