<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader type="heading"></v-skeleton-loader>
        <v-skeleton-loader type="list-item"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        :align="$vuetify.breakpoint.smAndUp ? 'start' : 'center'"
      >
        <v-skeleton-loader type="heading"></v-skeleton-loader>
      </v-col>
    </v-row>
    <br />
    <v-row>
      <v-col cols="12" sm="6" align="start">
        <v-skeleton-loader type="heading" class="pb-4"></v-skeleton-loader>
        <v-skeleton-loader
          tile
          type="image"
          class="loader-height"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" sm="6" align="start">
        <v-skeleton-loader type="heading" class="pb-4"></v-skeleton-loader>
        <v-skeleton-loader
          tile
          type="image"
          class="loader-height"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <br />
    <v-row>
      <v-col cols="12" sm="6" align="start">
        <v-skeleton-loader type="heading" class="pb-4"></v-skeleton-loader>
        <v-skeleton-loader
          tile
          type="image"
          class="loader-height"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" sm="6" align="start">
        <v-skeleton-loader type="heading" class="pb-4"></v-skeleton-loader>
        <v-skeleton-loader
          tile
          type="image"
          class="loader-height"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <br />
    <v-row>
      <v-col cols="12" sm="6" align="start">
        <v-skeleton-loader type="heading" class="pb-4"></v-skeleton-loader>
        <v-skeleton-loader
          tile
          type="image"
          class="loader-height"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" sm="6" align="start">
        <v-skeleton-loader type="heading" class="pb-4"></v-skeleton-loader>
        <v-skeleton-loader
          tile
          type="image"
          class="loader-height"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <br />
    <v-row>
      <v-col cols="12" sm="6" align="start">
        <v-skeleton-loader type="heading" class="pb-4"></v-skeleton-loader>
        <v-skeleton-loader
          tile
          type="image"
          class="loader-height"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" sm="6" align="start">
        <v-skeleton-loader type="heading" class="pb-4"></v-skeleton-loader>
        <v-skeleton-loader
          tile
          type="image"
          class="loader-height"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "patient-form-loader",
  data() {
    return {};
  }
};
</script>

<style scoped>
.loader-height {
  height: 50px;
}
</style>
