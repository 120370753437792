<template>
  <span>
    <div v-if="!adminView">
      <h1
        class="white--text"
        :class="$vuetify.breakpoint.mdAndUp ? 'venusa-font' : ''"
      >
        VENUSA LIVE
      </h1>
    </div>
    <v-card
      min-height="800"
      :width="$vuetify.breakpoint.mdAndUp ? '80%' : '100%'"
      class="fit-content-width"
      :class="{ 'px-5': $vuetify.breakpoint.mdAndUp }"
      :elevation="flat ? 0 : 24"
    >
      <patient-form-loader v-if="loading" />
      <error-view v-else-if="errorLoading" />
      <empty-section
        v-else-if="emptyForm"
        title="¡No hay preguntas asociadas al formulario!"
        subtitle=""
        icon="mdi-application-cog"
      />
      <v-container
        class="card"
        v-else-if="sections"
        :class="{ 'pb-0': $vuetify.breakpoint.xlOnly }"
      >
        <v-row :class="$vuetify.breakpoint.xlOnly ? 'pb-0' : 'py-4'">
          <v-col :class="{ 'pb-0': $vuetify.breakpoint.xlOnly }">
            <div
              class=" text-sm-h4 text-h6"
              :class="primary ? 'primary--text' : 'secondary--text'"
            >
              Formulario de registro
            </div>
          </v-col>
        </v-row>
        <v-row justify="center" class="py-0">
          <v-col cols="12" align="center" class="py-0">
            <v-stepper
              v-model="activeStep"
              alt-labels
              class="stepper elevation-0 pb-0 py-0"
            >
              <v-stepper-header>
                <v-stepper-step
                  :complete="activeStep > 0"
                  :step="0"
                  v-if="!$vuetify.breakpoint.xsOnly"
                  :color="primary ? 'primary' : 'secondary'"
                  class="stepper-number-color px-0 stepper-icon-color stepper-label"
                >
                  <span :class="primary ? 'primary--text' : 'secondary--text'"
                    >Datos Básicos</span
                  >
                </v-stepper-step>
                <template v-for="(section, index) in sections">
                  <v-divider
                    color="white"
                    v-if="!$vuetify.breakpoint.xsOnly"
                    :key="`${index}-divider-${section.id}`"
                  />
                  <v-stepper-step
                    v-if="!$vuetify.breakpoint.xsOnly"
                    :key="`${index}-stepper-${section.id}`"
                    :complete="activeStep > index + 1"
                    :step="index + 1"
                    :color="primary ? 'primary' : 'secondary'"
                    class="stepper-number-color  px-0 stepper-icon-color stepper-label"
                  >
                    <span :class="primary ? 'primary--text' : 'secondary--text'"
                      >Sección {{ index + 1 }}</span
                    >
                  </v-stepper-step>
                </template>
                <div
                  v-if="$vuetify.breakpoint.xsOnly"
                  class="d-flex justify-center  fill-width"
                  :class="primary ? 'primary--text' : 'secondary--text'"
                >
                  Página {{ activeStep + 1 }} de {{ sections.length + 1 }}
                </div>
                <v-stepper-items class="fill-width">
                  <v-stepper-content
                    class="pt-0"
                    :step="0"
                    :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
                    ><v-container>
                      <v-form ref="basicData">
                        <registration-basic-data
                          @submit.prevent="submit"
                          ref="registrationBasicData"
                          :patientToUpdate="patientToUpdate"
                          :update="update"
                          :primary="primary"
                          :adminView="adminView"
                        />
                      </v-form>
                    </v-container>
                  </v-stepper-content>
                  <v-stepper-content
                    class="pt-0"
                    :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
                    v-for="(section, index) in sections"
                    :key="`${index}-content-${section.id}`"
                    :step="index + 1"
                  >
                    <v-form
                      :ref="`formSectionData-${index + 1}`"
                      @submit.prevent="submit"
                    >
                      <patient-form-section
                        :ref="`sectionData-${section.id}`"
                        :section="section"
                        :update="update"
                        :primary="primary"
                      />
                    </v-form>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper-header>
            </v-stepper>
          </v-col>
        </v-row>
        <!-- Action Buttons -->
        <v-row class="pb-7 px-4 ">
          <v-col
            :class="$vuetify.breakpoint.smAndDown ? 'mb-6 pb-0' : 'py-0'"
            align="end"
            cols="12"
            sm="6"
          >
            <v-btn
              v-if="activeStep != 0"
              block
              @click="previousSection()"
              :disabled="sendingData"
              color="gray"
            >
              Anterior
            </v-btn>
          </v-col>
          <v-col
            :class="$vuetify.breakpoint.smAndDown ? '' : 'py-0'"
            align="end"
            cols="12"
            sm="6"
          >
            <v-btn
              @click="nextSection()"
              :color="primary ? 'primary' : 'secondary'"
              block
              :loading="sendingData"
              class="button-text-color"
            >
              {{
                activeStep == sections.length
                  ? adminView
                    ? "Guardar"
                    : "Enviar respuesta"
                  : "Continuar"
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <success-message
      :showModal="showSuccessMessage"
      title="¡Respuestas registradas exitosamente!"
      message="Recibirá un correo de bienvenida confirmando el registro de sus respuestas"
      @okAction="successfulTransaction()"
    />
    <success-message
      :showModal="showSuccessUpdateMessage"
      title="¡Respuestas actualizadas exitosamente!"
      message="Las respuestas fueron actualizadas satisfactoriamente. Presione el botón OK para volver a venusa.live"
      @okAction="successfulTransaction()"
    />
    <warning-message
      :showModal="showWarningUpdateMessage"
      title="¡Faltan campos requeridos!"
      message="Hay uno o más campos requeridos sin completar. Por favor, complete los campos para continuar con el formulario."
      :onlyReadNotification="true"
      @okAction="showWarningUpdateMessage = false"
    />
  </span>
</template>

<script>
import SuccessMessage from "@/components/common/notifications/SuccessMessage";
import WarningMessage from "@/components/common/notifications/WarningMessage";
import PatientFormSection from "./PatientFormSection";
import RegistrationBasicData from "./RegistrationBasicData";
import Repository from "@/services/repositories/repositoryFactory";
import PatientFormLoader from "@/components/common/loaders/PatientFormLoader";
import ErrorView from "@/components/common/notifications/ErrorView";
import EmptySection from "@/components/common/notifications/EmptySection";
import jwt from "@/common/jwt.service";
import { STATUS } from "@/config/constants";
import { THERAPIST } from "@/config/constants";

const FormRepository = Repository.get("form");
const PatientRepository = Repository.get("patient");

export default {
  name: "patient-form",
  components: {
    "patient-form-section": PatientFormSection,
    "registration-basic-data": RegistrationBasicData,
    "success-message": SuccessMessage,
    "warning-message": WarningMessage,
    "patient-form-loader": PatientFormLoader,
    "error-view": ErrorView,
    "empty-section": EmptySection
  },
  props: {
    update: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    adminView: {
      type: Boolean,
      default: false
    },
    formPatient: { type: Number, default: 0 }
  },
  data() {
    return {
      activeStep: 0,
      loading: false,
      sendingData: false,
      errorLoading: false,
      form: null,
      sections: null,
      showSuccessMessage: false,
      showSuccessUpdateMessage: false,
      showWarningUpdateMessage: false,
      patientToUpdate: null,
      emptyForm: false
    };
  },
  methods: {
    submit() {
      return false;
    },
    async nextSection() {
      if (this.activeStep === 0) {
        if (this.$refs.basicData.validate()) {
          this.activeStep++;
        } else {
          this.showWarningUpdateMessage = true;
        }
      } else {
        if (this.$refs[`formSectionData-${this.activeStep}`][0].validate()) {
          if (this.activeStep === this.sections.length) {
            if (this.update) {
              this.updateData();
            } else {
              this.sendData();
            }
          } else {
            this.activeStep++;
          }
        } else {
          this.showWarningUpdateMessage = true;
        }
      }
    },
    previousSection() {
      this.activeStep--;
    },
    openSuccessMessage(visibility) {
      this.showSuccessMessage = visibility;
    },
    openSuccessUpdateMessage(visibility) {
      this.showSuccessUpdateMessage = visibility;
    },
    successfulTransaction() {
      this.openSuccessMessage(false);
      if (this.adminView) {
        this.$emit("loadPatients");
      } else {
        jwt.destroyToken();
        this.$router.push({ name: "website-container-home" });
      }
    },
    async loadData() {
      this.loading = true;
      if (this.update) {
        if (this.adminView) {
          this.form = await FormRepository.getFormResponseAdminView(
            this.formPatient
          )
            .catch(() => {
              this.errorLoading = true;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.form = await FormRepository.getFormResponse()
            .catch(() => {
              this.errorLoading = true;
            })
            .finally(() => {
              this.loading = false;
            });
        }
        this.patientToUpdate = this.form?.formPatients[0].patient;
      } else {
        this.form = await FormRepository.getFormByIdForResponse(
          process.env.VUE_APP_VENUSA_LIVE_FORM_ID
        )
          .catch(() => {
            this.errorLoading = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
      this.sections = this.form?.sections;
      if (!this.form || !this.form.sections?.length) {
        this.emptyForm = true;
      }
    },
    async sendData() {
      this.sendingData = true;
      const basicData = this.$refs.registrationBasicData.retrieveData();
      let sectionData = [];
      this.sections.forEach(section => {
        let value = this.$refs[`sectionData-${section.id}`][0].retrieveData();
        sectionData = [...sectionData, ...value];
      });
      let date = new Date();
      date =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();

      let data = {
        patient: {
          ...basicData,
          record: {
            content: ""
          },
          status: {
            id: STATUS.ACTIVE.id
          },
          therapist: {
            id: THERAPIST.MAIN.id
          }
        },
        formPatient: {
          patient: {
            id: null
          },
          answerDate: date,
          form: {
            id: this.form.id
          },
          answers: sectionData
        }
      };
      await PatientRepository.sendFormAnswers(data)
        .then(() => {
          this.openSuccessMessage(true);
        })
        .finally(() => {
          this.sendingData = false;
        });
    },
    async updateData() {
      this.sendingData = true;
      const basicData = this.$refs.registrationBasicData.retrieveData();
      let sectionData = [];
      this.sections.forEach(section => {
        let value = this.$refs[`sectionData-${section.id}`][0].retrieveData();
        sectionData = [...sectionData, ...value];
      });

      delete basicData.relatives;
      let data = {
        patient: {
          ...basicData,
          therapist: {
            id: THERAPIST.MAIN.id
          }
        },
        formPatient: {
          id: this.form?.formPatients[0].id,
          answers: sectionData
        }
      };
      if (this.adminView) {
        await PatientRepository.updateFormAnswersAdminView(data)
          .then(() => {
            this.openSuccessUpdateMessage(true);
          })
          .finally(() => {
            this.sendingData = false;
          });
      } else {
        await PatientRepository.updateFormAnswers(data)
          .then(() => {
            this.openSuccessUpdateMessage(true);
          })
          .finally(() => {
            this.sendingData = false;
          });
      }
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<style scoped>
.card {
  padding-top: 3px !important;
  padding-bottom: 0px !important;
}

.stepper {
  background: white;
}
.stepper-number-color ::v-deep .v-stepper__step__step {
  color: white !important;
}
.stepper-icon-color ::v-deep i {
  color: white !important;
}
.stepper-label ::v-deep .v-stepper__label {
  text-align: center !important;
}
.fit-content-width {
  width: fit-content;
}
.venusa-font {
  font-size: 60px;
}
.fill-width {
  width: 100%;
}
</style>
